import React from 'react';
import { ContentModal } from '@brainstud/ui/Modals/ContentModal';
import { useModals } from 'Providers/ModalProvider/useModals';
import { sanitizer } from 'Utils/Sanitizer';

type Props = {
  data: {
    label: string;
    tooltip: string;
  };
};

const MethodTooltip = ({ data }: Props) => {
  const { closeModal } = useModals();
  return (
    <ContentModal
      onClose={closeModal}
      onClickOutside={closeModal}
      style={{ width: '45rem' }}
    >
      <h2>{data?.label}</h2>
      <div dangerouslySetInnerHTML={{ __html: sanitizer(data?.tooltip) }} />
    </ContentModal>
  );
};

export default MethodTooltip;
