import React, { MouseEvent, useCallback, useEffect, useRef } from 'react';
import { Button } from '@brainstud/ui/Buttons/Button';
import { HelpOutline } from '@mui/icons-material';
import classNames from 'classnames/bind';
import { Link } from 'Components/Link';
import MethodTooltip from 'Modules/account-panel/Views/Unauthenticated/MethodView/MethodTooltip';
import { useRouter } from 'next/router';
import { useModals } from 'Providers/ModalProvider/useModals';
import { sanitizer } from 'Utils/Sanitizer';
import styles from './AuthenticationMethod.module.css';

const classes = classNames.bind(styles);

type Props = {
  /** Main indicator of the authentication route to choose */
  label: string;
  /** A complementary description to guide users. Accepts HTML. */
  description?: string;
  /** A helpful icon to distinguish the method */
  icon?: string;
  /** Helpful extra information behind a modal to clarify the authentication method. */
  tooltip?: string;
  /** The URL the authentication method should lead to. Defaults to '/auth/login' */
  url?: string;
};

/**
 * AuthenticationMethod.
 *
 * Shows information of a single authentication method.
 */
export const AuthenticationMethod = ({
  label,
  description,
  icon,
  tooltip,
  url,
}: Props) => {
  const router = useRouter();
  const descriptionRef = useRef<null | HTMLSpanElement>(null);
  useEffect(() => {
    if (descriptionRef.current) {
      const handleSubLinkClick: EventListener = (event) => {
        event.preventDefault();
        const { href } = event.currentTarget as HTMLAnchorElement;
        if (href.startsWith(window.location.origin)) {
          router.push(href);
        } else {
          window.location.href = href;
        }
        return false;
      };
      const els = descriptionRef.current.querySelectorAll('a');
      els.forEach((el) => {
        el.addEventListener('click', handleSubLinkClick);
      });
      return () => {
        els.forEach((el) =>
          el.removeEventListener('click', handleSubLinkClick)
        );
      };
    }
  }, [router]);
  const { openModal } = useModals();
  const handleTooltip = useCallback(
    (
      event: MouseEvent<HTMLElement>,
      data: { label: string; tooltip: string }
    ) => {
      event.preventDefault();
      openModal(MethodTooltip, { data });
    },
    [openModal]
  );

  return (
    <Link href={url || '/auth/login'} className={classes(styles.base)}>
      {/* eslint-disable-next-line @next/next/no-img-element */}
      {icon && <img src={icon} alt="" className={classes(styles.icon)} />}
      <span className={classes(styles.methodText, { extraMargin: !!icon })}>
        <span className={classes(styles.label)}>{label}</span>
        {description && (
          <span
            ref={descriptionRef}
            className={classes(styles.description)}
            dangerouslySetInnerHTML={{ __html: sanitizer(description) }}
          />
        )}
      </span>
      {tooltip && (
        <Button
          title={tooltip}
          onClick={(event) => handleTooltip(event, { label, tooltip })}
          quiet
          round
          className={classes(styles.tooltip)}
        >
          <HelpOutline />
        </Button>
      )}
    </Link>
  );
};
